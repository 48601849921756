jQuery(document).ready(function($){
  $('.js-share').click(function(e){
    e.preventDefault();
    if ($('.share__addthis').hasClass('active')){
      $('.share__addthis').removeClass('active');
    }else{
      $('.share__addthis').addClass('active');
    }

  });
});
