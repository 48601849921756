jQuery(document).ready(function($){

$('.post-card-image a').hover(
    function(){
        $(this).parent().parent().find('.post-card-text a.btn').addClass('hover');
    },
    function(){
        $(this).parent().parent().find('.post-card-text a.btn').removeClass('hover');
    }
);

$('.post-card .post-card-text a.btn').hover(
    function() {
        $(this).parent().parent().find('.post-card-image a').addClass('hover');
    },
    function() {
        $(this).parent().parent().find('.post-card-image a').removeClass('hover');
    }
  );

});
