function getParameterByName(name, url) {
   if (!url) url = window.location.href;
   name = name.replace(/[\[\]]/g, "\\$&");
   var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
       results = regex.exec(url);
   if (!results) return null;
   if (!results[2]) return '';
   return decodeURIComponent(results[2].replace(/\+/g, " "));
}

jQuery(document).ready(function($){
    if ($('.render-contact').length > 0){
      var job_id = getParameterByName('jid');
      var job_title = getParameterByName('jt');

      if (job_id != null && job_title != null) {
        var str = "I would like to apply to the position of "+job_title+" ("+job_id+").\n";
        $('textarea[name="message"]').val(str);
      }
    }

});
