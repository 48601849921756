jQuery(document).ready(function($){
  if ($('.job-card').length > 0) {
    $('.job-card .btn.link').click(function(){
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $('#'+$(this).attr('data-action')).removeClass('active');
      }else{
        $(this).addClass('active');
        $('#'+$(this).attr('data-action')).addClass('active');
      }
    });

  }

});
