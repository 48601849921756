// jQuery
(function($){

    var $window = $(window);
    var $document = $(document);

    var postcode_marker = '';

    var SAC = {

        filtered_doctors: '',
        page_nav_offset: 0,

        app: {

            // Scroll to content when URL contains # with offset
            hash_scroll: function(){

                if( window.location.hash ){
                    var $section = $(window.location.hash);
                    if( $section.length ){
                        var offset = 40;
                        var scroll_to = $section.offset().top;
                        if( $('.page-nav').length ){
                            offset = offset + $('.page-nav').outerHeight();
                        }
                        $('html,body').stop().animate({ scrollTop: scroll_to - offset }, 700, 'easeInOutExpo');
                    }
                }

            },

            // dropkick - https://github.com/Robdel12/DropKick
            selects: function(){

                // // blog filters
                // $('.js-blog-filter').dropkick({
                //     mobile: true,
                //     change: function(){
                //         var val = this.value;
                //         window.location = val;
                //     }
                // });

                // // form select
                // $('.js-form-select').dropkick({
                //     mobile: true,
                //     change: function(){

                //     }
                // });

            },

            // search_form: function(){

            //     $('.js-search-form').validate({
            //         messages: {
            //             query: 'Please enter a search keyword',
            //         }
            //     });

            // },

            // carousels - https://github.com/OwlCarousel2/OwlCarousel2
            carousels: function(){

                /*
                var nav_arrows = [
                    '<svg class="icon icon-chevron-left"><use xlink:href="'+globals.assets_url+'/images/icons.svg#icon-chevron-left"></use></svg>',
                    '<svg class="icon icon-chevron-right"><use xlink:href="'+globals.assets_url+'/images/icons.svg#icon-chevron-right"></use></svg>'
                ];
                */

                // images carousel
                $('.js-images-carousel').owlCarousel({
                    loop: true,
                    dots: true,
                    autoplay: true,
                    smartSpeed: 700,
                    autoplayTimeout: 5000,
                    nav: false,
                    items: 1
                });

                $('.js-images-carousel-caption').owlCarousel({
                    loop: true,
                    dots: true,
                    autoplay: false,
                    smartSpeed: 700,
                    autoplayTimeout: 5000,
                    nav: true,
                    items: 1
                });

                var xxxxx = $('.js-images-carousel-navbuttons').owlCarousel({
                    loop: true,
                    dots: true,
                    autoplay: true,
                    smartSpeed: 700,
                    autoplayTimeout: 5000,
                    nav: true,
                    items: 1,
                    stagePadding: 0,
                    margin: 0,
                    singleItem: true
                });
                // over and blur
                // $('.js-images-carousel-navbuttons .item')
                //   .on('mouseenter',function(e){
                //     $(this).closest('.owl-carousel').trigger('play.owl.autoplay');
                //   })
                //   .on('mouseleave',function(e){
                //     $(this).closest('.owl-carousel').trigger('stop.owl.autoplay');
                //   });

//                 var fixOwl = function(){
//                         // var $stage = $('.owl-stage'),
//                         //     stageW = $stage.width(),
//                         //     $el = $('.owl-item'),
//                         //     elW = 0;
//                         // $el.each(function() {
//                         //     elW += $(this).width()+ +($(this).css("margin-right").slice(0, -2))
//                         // });
//                         // if ( elW > stageW ) {
//                         //     $stage.width( elW );
//                         // };
//                     console.log('hey');
//                     }
// xxxxx.on("resize.owl.carousel", function () {
//   setTimeout(function () {
//     fixOwl();
//   }, 50);
// });


                // feed carousel
                $('.js-feed-carousel').owlCarousel({
                    loop: true,
                    dots: true,
                    autoplay: true,
                    smartSpeed: 700,
                    autoplayTimeout: 4000,
                    nav: false,
                    autoWidth: true,
                    margin: 20,
                    stagePadding: 100,
                    items: 4,
                    center:true
                });

                $('.js-feed-post:not(".js-carousel-off")').owlCarousel({
                    loop:false,
                    margin:32,
                    nav: false,
                    dots: true,
                    responsive:{
                        0:{
                            items:1
                        },
                        480:{
                            items:1
                        },
                        768:{
                            items:2
                        },
                        1024:{
                            items:3
                        }
                    }
                })


            },


            events: {

                global: function(){


                },

                // User input events such as clicks and key presses
                input: function(){

                    // Keyboard events
                    $document.on('keyup', function(e){

                        // Escape key
                        if (e.keyCode === 27) {
                            $('.js-search-overlay').removeClass('is-active');
                            $('.js-nav-mobile').removeClass('is-active');
                            $('.js-hamburger').removeClass('is-active');
                        }

                    });

                    // Mobile menu
                    $(document).on('click', '.js-menu-toggle', function(e){
                        $('.js-hamburger').toggleClass('is-active');
                        $('.js-nav-mobile').toggleClass('is-active');
                        e.preventDefault();
                    });

                    // Back to top
                    $(document).on('click', '.js-back-to-top', function(e){
                        $('html,body').stop().animate({ scrollTop: 0 }, 0, 'easeInOutQuad');
                        e.preventDefault();
                    });

                    // Show sub menu
                    $(document).on('click', '.menu-primary-container .menu-item-has-children > a', function(e){
                        $('.menu-item').not($(this).parent('.sub-menu')).removeClass('is-active');
                        $(this).parent('.menu-item').toggleClass('is-active');
                        e.stopPropagation();
                        e.preventDefault();
                    });

                    // Close sub menu
                    $(document).on('click', function(){
                        $('.menu-item').removeClass('is-active');
                    });

                    // Show mobile sub menu
                    $(document).on('click', '.nav-mobile .menu-item-has-children > a', function(e){
                        $(this).siblings('.sub-menu').addClass('is-active');
                        $('.nav-mobile-underlay').addClass('is-active');
                        e.stopPropagation();
                        e.preventDefault();
                    });

                    // Hide mobile sub menu
                    $(document).on('click', '.nav-mobile-underlay', function(e){
                        $('.nav-mobile .sub-menu').removeClass('is-active');
                        $('.nav-mobile-underlay').removeClass('is-active');
                        e.preventDefault();
                    });

                    // Show search overlay
                    $(document).on('click', '.js-search-toggle', function(e){
                        $('.js-search-overlay').addClass('is-active');
                        $('.js-search-overlay [name="search"]').focus();
                        e.preventDefault();
                    });

                    // Close search overlay
                    $(document).on('click', '.js-close-search', function(e){
                        $('.js-search-overlay').removeClass('is-active');
                        e.preventDefault();
                    });

                    // // Form input
                    // $(document).on('focus', '.field-input', function(){
                    //     $(this).parents('.field-group').addClass('is-active');
                    // });

                    // $(document).on('focusout', '.field-input', function(){
                    //     var $this = $(this);
                    //     if( !$this.val() ){
                    //         $(this).parents('.field-group').removeClass('is-active');
                    //     }
                    // });

                    // // Accordion
                    $(document).on('click', '.js-faq-question', function(){
                        $(this).parent().toggleClass('is-active');
                    });

                    // // Checklist
                    // $(document).on('click', '.js-checklist', function(){
                    //     $(this).toggleClass('is-active');
                    // });

                    var box = $('section.read-more-enabled');
                    // var max = box.offset().top + box.outerHeight();
                    // var min = max - 32;
                    // var checkRange = function(y) {
                    //   return (y>min && y <= max);
                    // }
                    // box.click(function(e){
                    //   if (checkRange(e.pageY)) {
                    //     box.toggleClass('read-more-expanded');
                    //   }
                    // });
                    box.append('<div class="btn_read-more-toggle"></div>').click(function(e){
                      $(this).toggleClass('read-more-expanded');
                    });

                }

            },

            polyfills: function(){

                // remove click delay on mobile devices - https://github.com/ftlabs/fastclick
                FastClick.attach(document.body);

                // svg - https://github.com/jonathantneal/svg4everybody
                svg4everybody();

                // object fit for images - http://bfred-it.github.io/object-fit-images
                window.objectFitImages();

            },

            // Runs when DOM is ready
          ready: function(){

            // polyfills
                SAC.app.polyfills();

                // input events
                SAC.app.events.input();

                // global events
                SAC.app.events.global();

                // hash scroll
                SAC.app.hash_scroll();

                // // load carousels
                // SAC.app.carousels();

                // custom select dropdowns
                // SAC.app.selects();

                // search form
                // SAC.app.search_form();

                $('p:empty').remove();

          },

            // Runs when page has fully loaded
            load: function(){

                // load carousels
                SAC.app.carousels();

                // remove empty <p>
                $('p:empty').remove();
            },

            // Runs while scrolling
            scroll: function(){

                var scroll_pos = $window.scrollTop();

                if (scroll_pos > 100) {
                    $('.js-back-to-top').css({ opacity: '0.5' });
                    $('.nav-mobile-toggle').addClass('is-active');
                } else {
                    $('.js-back-to-top').css({ opacity: '0' });
                    $('.nav-mobile-toggle').removeClass('is-active');
                }

            },

            // Runs when browser is resized
            resize: function(){

            }

        }

    };

    $(function(){
      SAC.app.ready();
        console.time('Load');
    });

    $window.on('load', function(){
      SAC.app.load();
        console.timeEnd('Load');
    });

    $window.on('scroll', function(){
      SAC.app.scroll();
    });

    var resize;

    $window.on('resize', function(){
      clearTimeout(resize);
      resize = setTimeout(function(){
        SAC.app.resize();
      }, 70);
    });

})(jQuery);
